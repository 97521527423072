import classNames from "classnames/bind";
import PropTypes from "prop-types";
import * as React from "react";
import {Controller} from "react-hook-form";
import InputMask from "react-input-mask";
import * as fieldStyles from "./field.module.css";
import * as inputStyles from "./input.module.css";

const cx = classNames.bind(inputStyles);

const Input = ({
    register,
    control,
    label,
    name,
    type,
    placeholder,
    required,
    disabled,
    icon,
    iconPosition,
    rules,
    error,
    flat,
    showLabel,
}) => {
    const inputClasses = cx({
        input: true,
        input_textarea: type === "textarea",
        input_flat: flat,
        input_error: error,
        input_withIcon: icon,
        input_withIconRight: iconPosition === "right",
    });

    const iconClasses = cx({
        input__icon: !!icon,
        input__icon_right: !!icon && iconPosition === "right",
    });

    const getInput = () => {
        if (type === "textarea") {
            return (
                <textarea
                    className={inputClasses}
                    id={name}
                    placeholder={placeholder}
                    disabled={disabled}
                    {...register(name, {required, disabled, ...rules})}
                    aria-label={label ? undefined : placeholder || name}
                />
            );
        }

        if (type === "tel") {
            return (
                <Controller
                    name={name}
                    control={control}
                    rules={{...rules, required}}
                    render={({field: {onChange, value, name: fieldName}}) => (
                        <InputMask
                            mask="+7 (999) 999-99-99"
                            maskChar=" "
                            value={value}
                            onChange={onChange}
                            disabled={disabled}
                        >
                            {(inputProps) => (
                                <input
                                    {...inputProps}
                                    id={fieldName}
                                    type="tel"
                                    name={fieldName}
                                    className={inputClasses}
                                    placeholder={placeholder}
                                    disabled={disabled}
                                    aria-label={label ? undefined : placeholder || fieldName}
                                />
                            )}
                        </InputMask>
                    )}
                />
            );
        }

        return (
            <input
                className={inputClasses}
                id={name}
                type={type}
                placeholder={placeholder}
                disabled={disabled}
                {...register(name, {required, disabled, ...rules})}
                aria-label={label ? undefined : placeholder || name}
            />
        );
    };

    return (
        <div className={fieldStyles.field}>
            {label && showLabel && (
                <label htmlFor={name} className={fieldStyles.field__label}>
                    {label}
                </label>
            )}
            <div className={inputStyles.input__wrapper}>
                {getInput()} {icon && <div className={iconClasses}>{icon}</div>}
            </div>
            {error && (
                <p className={fieldStyles.field__error}>
                    {error?.message || `Поле${label ? ` ${label}` : ""} обязательно к заполнению`}
                </p>
            )}
        </div>
    );
};

Input.defaultProps = {
    type: "text",
    iconPosition: "left",
    rules: {},
    required: false,
    label: "",
    placeholder: "",
    disabled: false,
    icon: null,
    error: null,
    flat: false,
    showLabel: true,
};

Input.propTypes = {
    register: PropTypes.func.isRequired,
    control: PropTypes.shape({}).isRequired,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    name: PropTypes.string.isRequired,
    type: PropTypes.string,
    required: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.string,
        PropTypes.exact({
            value: PropTypes.bool,
            message: PropTypes.string,
        }),
    ]),
    disabled: PropTypes.bool,
    flat: PropTypes.bool,
    icon: PropTypes.node,
    iconPosition: PropTypes.oneOf(["left", "right"]),
    rules: PropTypes.shape({}),
    error: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
    showLabel: PropTypes.bool,
};

export default Input;
