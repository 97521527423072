import PropTypes from "prop-types";
import {useCallback, useState} from "react";
import * as React from "react";
import {useForm} from "react-hook-form";
import Button from "../UI/Button";
import * as formStyles from "./form.module.css";

const Form = ({fields, buttonText, note, moreButtons, onSubmit: clientOnSubmit}) => {
    const {
        formState: {errors},
        handleSubmit,
        ...restHookData
    } = useForm({
        defaultValues: fields.reduce((acc, {name, value = ""}) => ({...acc, [name]: value}), {}),
    });

    const [formPending, setFormPending] = useState(false);

    const onSubmit = useCallback(
        async (data) => {
            if (typeof clientOnSubmit === "function") {
                setFormPending(true);
                await clientOnSubmit(data);
                setFormPending(false);
            }
        },
        [clientOnSubmit],
    );

    return (
        <form onSubmit={handleSubmit(onSubmit)} className={formStyles.form} noValidate>
            <div>
                {fields.map((field, idx) => field.render({error: errors[field.name], key: idx, ...restHookData}))}
                {note}
            </div>
            <div>
                {moreButtons}
                <Button variant="primary" type="submit" loading={formPending}>
                    {buttonText}
                </Button>
            </div>
        </form>
    );
};

Form.defaultProps = {
    buttonText: "Отправить",
};

Form.propTypes = {
    fields: PropTypes.arrayOf(
        PropTypes.exact({
            name: PropTypes.string.isRequired,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.array]),
            render: PropTypes.func.isRequired,
        }),
    ).isRequired,
    buttonText: PropTypes.string,
    note: PropTypes.element,
    moreButtons: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
    onSubmit: PropTypes.func,
};

export default Form;
